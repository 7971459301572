import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Text, Icon } from "@website-builder/ui/shared/elements";
import { MainWrapper, ErrorSection, TextAreaWrapper } from "./styles";

const Input = (props) => {
	const {
		label,
		required,
		error = [{}, () => {}],
		onChange = () => {},
		showErrors,
		name,
		helpText,
		className,
		fieldType,
		hidden,
		placeholder,
		...restProps
	} = props;
	const [focused, setFocused] = useState(false);
	const [errors, setErrors] = error;
	const [inputValue, setInputValue] = useState("");

	const validate = (name, field, value, fieldType) => {
		if (field === "email") {
			if (!value && !required) {
				setErrors({
					...errors,
					[name]: "",
				});
				return;
			} else if (!value && required) {
				setErrors({
					...errors,
					[name]: "Enter email address",
				});
				return;
			} else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
				setErrors({
					...errors,
					[name]: "Invalid email address",
				});
				return;
			} else {
				setErrors({
					...errors,
					[name]: "",
				});
				return;
			}
		} else if (fieldType === "phonenumber") {
			if (!value && !required) {
				setErrors({
					...errors,
					[name]: "",
				});
				return;
			} else if (!value && required) {
				setErrors({
					...errors,
					[name]: "Enter Phone Number",
				});
				return;
			} else if (
				!/^(?:(?:\+|00)\d{1,3}[-. ]?)?(?:\(\d{1,4}\)|\d{1,4})[-. ]?\d{1,4}[-. ]?\d{1,9}(?:[-. ]?\d{1,5})?$/.test(
					value,
				)
			) {
				setErrors({
					...errors,
					[name]: "Please enter a valid phone number.",
				});
				return;
			} else {
				setErrors({
					...errors,
					[name]: "",
				});
				return;
			}
		} else if (!value && required) {
			setErrors({
				...errors,
				[name]: "This is a required field",
			});
		} else {
			setErrors({
				...errors,
				[name]: "",
			});
		}
	};

	const handleChange = (e, field) => {
		e.persist();
		validate(e.target.name, e.target.type, e.target.value, field);
		onChange(e);
	};
	const handleBlur = (e, field) => {
		e.persist();
		validate(e.target.name, e.target.type, e.target.value, field);
		setFocused(false);
	};
	return (
		<MainWrapper className={className}>
			<Text
				variant="label_M"
				className={`${required ? "required" : ""} label-text`}
			>
				{label}
			</Text>
			{helpText ? (
				<Text variant="paragraph_S_bold" className="help-text">
					{helpText}
				</Text>
			) : null}
			{fieldType === "textarea" ? (
				<TextAreaWrapper>
					<textarea
						{...restProps}
						rows={3}
						name={name}
						type={fieldType}
						placeholder={placeholder}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
					></textarea>
				</TextAreaWrapper>
			) : (
				<input
					{...restProps}
					onChange={(e) => handleChange(e, fieldType)}
					onFocus={() => setFocused(true)}
					onBlur={(e) => handleBlur(e, fieldType)}
					name={name}
					placeholder={placeholder}
					className={errors[name] && showErrors && !focused ? "error" : null}
					onInvalid={(e) => e.preventDefault()}
				/>
			)}
			{errors[name] && showErrors && !focused ? (
				<ErrorSection className="error-section">
					<Icon iconName="warning" />
					<Text variant="paragraph_S" className="errorMessage">
						{errors[name]}
					</Text>
				</ErrorSection>
			) : null}
		</MainWrapper>
	);
};

Input.propTypes = {
	label: PropTypes.string,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	showErrors: PropTypes.bool,
};

Input.defaultProps = {
	label: "",
	required: false,
	showErrors: false,
};

export default Input;
